<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-date-picker :value="value" @change="handleChange" @pressEnter="check" @click="check"/>
    </div>
    <div v-else class="editable-cell-text-wrapper">
      {{ value || ' ' }}
      <a-icon type="edit" class="editable-cell-icon" @click="edit" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    text: String,
  },
  data() {
    return {
      value: this.text,
      editable: false,
    }
  },
  methods: {
    handleChange(e) {
      if (!e) {
        this.value = e
      } else {
        const value = moment(e).format('YYYY-MM-DD')
        console.log(value)
        this.value = value
      }
      this.check()
    },
    check() {
      this.editable = false
      this.$emit('change', this.value)
    },
    edit() {
      this.editable = true
    },
  },
}
</script>
