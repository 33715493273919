<template>
  <div v-if="loaded">
    <h4>{{ title }}
      <a-dropdown-button @click="handleAdd" style="float: right;">
        Legg til
        <template #overlay v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
          <a-menu>
            <a-menu-item key="1" @click="showImport = true">
              Importer fra excel
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown-button>
      <a-input placeholder="Søk..." @change="search" v-model="searchText" style="float: right; max-width: 300px"/>
    </h4>
    <div class="card" v-if="showImport">
      <div class="card-body">
        <a-form>
          <a-form-item label="Data">
            <a-textarea placeholder="Lim inn data fra excel" :rows="4" @change="convertInput" v-model="input" />
          </a-form-item>
          <a-button style="width: 150px;" type="primary"  class="mt-3">Importer</a-button>
        </a-form>
      </div>
    </div>
    <a-table :columns="columns" :dataSource="data">
      <template slot="string1" slot-scope="text, record" >
        <editable-cell :text="text" @change="onCellChange(record.key, 'string1', $event)" />
      </template>
      <template slot="string2" slot-scope="text, record" >
        <editable-cell :text="text" @change="onCellChange(record.key, 'string2', $event)" />
      </template>
      <template slot="string3" slot-scope="text, record" >
        <editable-cell :text="text" @change="onCellChange(record.key, 'string3', $event)" />
      </template>
      <template slot="string4" slot-scope="text, record" >
        <editable-cell :text="text" @change="onCellChange(record.key, 'string4', $event)" />
      </template>
      <template slot="number1" slot-scope="text, record" >
        <editable-cell :text="text" @change="onCellChange(record.key, 'number1', $event)" />
      </template>
      <template slot="number2" slot-scope="text, record" >
        <editable-cell :text="text" @change="onCellChange(record.key, 'number2', $event)" />
      </template>
      <template slot="number3" slot-scope="text, record" >
        <editable-cell :text="text" @change="onCellChange(record.key, 'number3', $event)" />
      </template>
      <template slot="select1" slot-scope="text, record" >
        <dropdown-cell
          :text="text" @change="onCellChange(record.key, 'select1', $event)"
          :options="options[1]"
        />
      </template>
      <template slot="select2" slot-scope="text, record" >
        <dropdown-cell
          :text="text" @change="onCellChange(record.key, 'select2', $event)"
          :options="options[2]"
        />
      </template>
      <template slot="select3" slot-scope="text, record" >
        <dropdown-cell
          :text="text" @change="onCellChange(record.key, 'select3', $event)"
          :options="options[3]"
        />
      </template>
      <template slot="employee1" slot-scope="text, record" >
        <dropdown-cell
          :text="text" @change="onCellChange(record.key, 'employee1', $event)"
          :options="employees"
        />
      </template>
      <template slot="employee2" slot-scope="text, record" >
        <dropdown-cell
          :text="text" @change="onCellChange(record.key, 'employee2', $event)"
          :options="employees"
        />
      </template>
      <template slot="employee3" slot-scope="text, record" >
        <dropdown-cell
          :text="text" @change="onCellChange(record.key, 'employee3', $event)"
          :options="employees"
        />
      </template>
      <template slot="employee4" slot-scope="text, record" >
        <dropdown-cell
          :text="text" @change="onCellChange(record.key, 'employee4', $event)"
          :options="employees"
        />
      </template>
      <template slot="date1" slot-scope="text, record" >
        <date-cell
          :text="text" @change="onCellChange(record.key, 'date1', $event)"
        />
      </template>
      <template slot="date2" slot-scope="text, record" >
        <date-cell
          :text="text" @change="onCellChange(record.key, 'date2', $event)"
        />
      </template>
      <template slot="date3" slot-scope="text, record" >
        <date-cell
          :text="text" @change="onCellChange(record.key, 'date3', $event)"
        />
      </template>
      <template slot="boolean1" slot-scope="text, record" >
        <a-checkbox
          :checked="text"
          @change="onCellChange(record.key, 'boolean1', $event.target.checked)"
        />
      </template>
      <template slot="boolean1" slot-scope="text, record" >
        <a-checkbox
          :checked="text"
          @change="onCellChange(record.key, 'boolean1', $event.target.checked)"
        />
      </template>
      <template slot="boolean2" slot-scope="text, record" >
        <a-checkbox
          :checked="text"
          @change="onCellChange(record.key, 'boolean2', $event.target.checked)"
        />
      </template>
      <template slot="boolean3" slot-scope="text, record" >
        <a-checkbox
          :checked="text"
          @change="onCellChange(record.key, 'boolean3', $event.target.checked)"
        />
      </template>
      <template slot="boolean4" slot-scope="text, record" >
        <a-checkbox
          :checked="text"
          @change="onCellChange(record.key, 'boolean4', $event.target.checked)"
        />
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-popconfirm
          v-if="data.length"
          title="Sure to delete?"
          @confirm="() => onDelete(record.key)"
        >
          <a href="javascript:;"><i class="fa fa-trash"></i></a>
        </a-popconfirm>
      </template>
    </a-table>
    <a-button class="mt-3" @click="downloadData()" :loading="loadingDownload">
      Last ned informasjon
    </a-button>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import EditableCell from '@/components/custom/visual/editableCell2'
import DropdownCell from '@/components/custom/visual/dropdownCell2'
import DateCell from '@/components/custom/visual/dateCell'
import moment from 'moment'
import exportFromJSON from 'export-from-json'
import axios from 'axios'

import { openNotification } from '@/services/powerAutomate'

export default {
  components: {
    EditableCell,
    DropdownCell,
    DateCell,
  },
  data() {
    return {
      docRef: null,
      data: [],
      oldData: [],
      title: '',
      columns: [],
      cols: [],
      employees: [],
      dataIndexes: [],
      loaded: false,
      showImport: false,
      input: '',
      searchText: '',
      options: [],
      loadingDownload: false,
    }
  },
  mounted() {
    let strings = 0
    let employees = 0
    let dates = 0
    let numbers = 0
    let booleans = 0
    let selects = 0
    this.docRef = firestore.collection('supportTables').doc(this.$route.params.id)
    this.docRef.get()
      .then(doc => {
        this.title = doc.data().title
        doc.data().dataStructure.forEach((item, i) => {
          if (item.type === 'string') {
            strings += 1
            this.dataIndexes[i] = ({
              title: item.title,
              regex: item.regex,
              index: `string${strings}`,
            })
          }
          if (item.type === 'employee') {
            employees += 1
            this.dataIndexes[i] = ({
              title: item.title,
              index: `employee${employees}`,
            })
          }
          if (item.type === 'number') {
            numbers += 1
            this.dataIndexes[i] = ({
              title: item.title,
              index: `number${numbers}`,
            })
          }
          if (item.type === 'boolean') {
            booleans += 1
            this.dataIndexes[i] = ({
              title: item.title,
              index: `boolean${booleans}`,
            })
          }
          if (item.type === 'date') {
            dates += 1
            this.dataIndexes[i] = ({
              title: item.title,
              index: `date${dates}`,
            })
          }
          if (item.type === 'select') {
            selects += 1
            this.dataIndexes[i] = ({
              title: item.title,
              index: `select${selects}`,
            })
            this.options[selects] = item.options
            console.log(this.options)
          }
          this.columns.push({
            title: item.title,
            dataIndex: this.dataIndexes[i].index,
            key: item.title,
            type: item.type,
            scopedSlots: { customRender: this.dataIndexes[i].index },
          })
        })
        if (['underDevelopment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
          this.columns.push({
            title: 'Slett',
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' },
          })
        }
        if (employees > 0) {
          this.findEmployees()
        }
        this.loaded = true
      })
    this.docRef.collection('data').orderBy('createdOn', 'desc').get()
      .then(snapshot => {
        this.data = []
        if (snapshot.empty) {
          console.log('No matching documents.')
          return
        }
        snapshot.forEach(doc => {
          this.data.push({
            key: doc.id,
            id: doc.id,
            ...doc.data(),
          })
          // map data such that the keys are the same as the dataIndexes
          // map only if there is values in the collection data
          this.dataIndexes.forEach((item, i) => {
            this.data[this.data.length - 1][item.index] = doc.data()[item.title]
            delete doc.data()[item.title]
          })
        })
        this.oldData = this.data
        this.$forceUpdate()
      })
  },
  methods: {
    findEmployees() {
      this.employees.push({
        value: 'none',
        text: 'Ingen Ansatt',
      })
      firestore.collection('users').get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            this.employees.push({
              value: doc.data().employeeID,
              text: doc.data().fullName,
            })
          })
        })
    },
    onCellChange(key, dataIndex, newValue) {
      // find the title of the dataIndex from dataIndexes
      const dataIndexObj = this.dataIndexes.find(item => item.index === dataIndex)
      // check if the dataIndexObj has an attribute of regex in that case apply the regex to the value and if it does not match, return error
      if (dataIndexObj.regex) {
        const regex = new RegExp(dataIndexObj.regex)
        if (!regex.test(newValue)) {
          openNotification('error', 'Feil format')
          return
        }
      }
      // create an object where the key is key and the value is value
      const updatedData = {
        [dataIndexObj.title]: newValue,
        lastEditedBy: this.$store.state.user.id,
        lastEditedOn: moment().format('YYYY-MM-DDTHH:mm:ss'),
      }
      this.docRef.collection('data').doc(key)
        .update(updatedData)
        .then(() => {
          const index = this.data.findIndex(item => key === item.key)
          this.$set(this.data, index, { ...this.data[index], [dataIndex]: newValue })
        })
    },
    async handleAdd() {
      // create const newRow with keys from dataIndexes and empty values
      const newRow = {}
      this.dataIndexes.forEach(item => {
        newRow[item.title] = ''
      })
      newRow.createdOn = moment().format('YYYY-MM-DDTHH:mm:ss')
      const key = await this.docRef.collection('data').add(newRow)
      // now lets map the newRow to the dataIndexes
      newRow.key = key.id
      newRow.id = key.id
      this.dataIndexes.forEach(item => {
        newRow[item.index] = newRow[item.title]
        delete newRow[item.title]
      })
      // add the newRow at the beginning of the data array
      this.data.unshift(newRow)
      // this.data.push(newRow)
    },
    onDelete(key) {
      this.docRef.collection('data').doc(key).delete()
      this.data = this.data.filter(obj => obj.key !== key)
      this.$forceUpdate()
    },
    downloadData() {
      this.loadingDownload = true
      // to call data from firebase https function instead
      const baseurl = 'https://europe-west1-skyportal2.cloudfunctions.net/getTables?api=wwqUGqJc7XzBvK258ZBF5OTVyFo&'
      const url = `${baseurl}&table=${this.$route.params.id}`
      axios.get(url)
        .then(response => {
          const data = response.data
          console.log(data)
          const fileName = this.title
          const exportType = exportFromJSON.types.csv
          const delimiter = ';'
          exportFromJSON({ data, fileName, exportType, delimiter })
        }).catch(error => {
          console.log(error)
          openNotification('error', 'Feil ved nedlasting av data')
        }).finally(() => {
          this.loadingDownload = false
        })
    },
    tsvToJson (tsvText) {
      // Split all the text into seperate lines on new lines and carriage return feeds
      var allTextLines = tsvText.split(/\r\n|\n/)
      // Split per line on tabs and commas
      var headers = allTextLines[0].split(/\t/)
      var lines = []
      for (var i = 1; i < allTextLines.length; i++) {
        var data = allTextLines[i].split(/\t/)
        if (data.length === headers.length) {
          var row = {}
          for (var j = 0; j < headers.length; j++) {
            row[headers[j]] = data[j]
          }
          lines.push(row)
        }
      }
      return {
        lines,
        headers,
      }
    },
    convertInput() {
      const { lines, headers } = this.tsvToJson(this.input)
      console.log(lines, headers)
      // Data
      // if (lines.length) {
      //   if (this.checkHeaders(headers, this.importType.headers)) {
      //     this.validationErrors = this.validateData(lines, this.importType.headers)
      //     if (this.validationErrors.length === 0) {
      //       this.data = lines
      //       this.$forceUpdate()
      //     }
      //   } else {
      //     openNotification('Data samsvarer ikke med valgt datatype')
      //   }
      // } else {
      //   openNotification('Feil ved lesing av data')
      // }
    },
    search(e) {
      const value = e.target.value
      this.data = this.oldData.filter((data) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1)
    },
  },
}
</script>

<style>

</style>
