<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-select :value="text" @change="handleChange" @pressEnter="check" show-search :filter-option="filterOption"
        option-filter-prop="children" style="width: 200px;">
        <a-select-option :value="option.value" v-for="option in options" :key="option.value" @click="check">
          {{ option.text }}
        </a-select-option>
      </a-select>
    </div>
    <div v-else class="editable-cell-text-wrapper">
      {{ findText(value) || ' ' }}
      <a-icon type="edit" class="editable-cell-icon" @click="edit" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    options: Array,
  },
  data() {
    return {
      value: this.text,
      editable: false,
    }
  },
  methods: {
    findText(value) {
      if (value === undefined || value === null || value === '') {
        return ''
      }
      if (this.options.length === 0) {
        return ''
      }
      const text = this.options.find(item => item.value === value).text
      return String(text)
    },
    handleChange(e) {
      const value = e
      this.value = value
      this.check()
    },
    check() {
      this.editable = false
      this.$emit('change', this.value)
    },
    edit() {
      this.editable = true
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style>

</style>
